import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import DataTable from 'components/shared/DataTable';
import { Column } from 'components/shared/DataTable/DataTable';
import {
  HALF_SCREEN_TABLE_TOP_HEIGHT,
  HIGHLIGHTED_ROW_CLASS_NAME,
} from 'core/constants';
import useInactiveRowClassName from 'hooks/datatable/useInactiveRowClassName';
import Grid from 'components/UI/Grid';
import { useStores } from 'stores/hooks/hooks';
import { Paper } from '@mui/material';
import {
  DataTableSelectAllChangeParams,
  DataTableSelectParams,
} from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import Loading from 'components/UI/Loading/Loading';
import { getDataTableStatusColumnBody } from 'components/shared/DataTable/DataTableColumns';
import { JSONSchema } from 'stores/types/types';
import {
  getMassEditTableRows,
  MassEditPlotData,
  SettingType,
  tableKeys,
} from './ResellerMassEdit.utils';

const SCROLL_HEIGHT = '70vh';

export interface MassEditPlotSettings extends MassEditPlotData {
  id: number;
  name: string;
}

export const getColumnTitle = (
  dssSchema: JSONSchema,
  settingsSchema: JSONSchema,
  key: string,
  settingType: SettingType | undefined,
) => {
  if (!settingType) {
    return undefined;
  }

  if (settingType === SettingType.dss) {
    // eslint-disable-next-line
    // @ts-ignore
    return dssSchema?.properties?.[key]?.title;
  }

  // eslint-disable-next-line
  // @ts-ignore
  return settingsSchema?.properties?.[key]?.title;
};

const COLUMN_STYLE = { minWidth: '100px' };
const COLUMN_STYLE_CHECK = { minWidth: '3rem' };

// This is a work-around fix for prime-react v8 issue with the DataTable component
// Tables with the 'select all' checkbox have an issue with double events being fired (does not prevent propagation)
const StyledDataTable = styled(DataTable)`
  & {
    tbody {
      td {
        pointer-events: none;
      }
    }
  }
`;

const DataTableWrapper = styled(Paper)`
  /* max-height: ${HALF_SCREEN_TABLE_TOP_HEIGHT}; */
  margin: 1rem;
  overflow: hidden;

  .${HIGHLIGHTED_ROW_CLASS_NAME} {
    td {
      background-color: ${({ theme }) => theme.color.selectedTableRowLime};
    }
  }
`;

const formatDecimal = (value: number | null): number =>
  parseFloat(value?.toFixed(2) as string);

const globalFilterFields = [
  'id',
  'name',
  'soilMin',
  'soilMax',
  'irrigationMin',
  'irrigationMax',
  'frequency',
  'disableF',
  'correctionFactor',
  'disableCF',
  'planDuration',
  'DaytimeParMin',
  'ATmax',
  'VPDmax',
  'VPDNIGHTmax',
  'SDIrate',
  'MDSrate',
  'MdsMin',
  'MdsRateChange',
  'MdsMinET',
  'PMBDelta',
  'WaterNominalPressure',
];

const ResellerPlotMassEdit: FC = observer(() => {
  const { t } = useTranslation('grower');
  const { resellersStore, plotsStore } = useStores();
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // DSS Settings
    soilMin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    soilMax: { value: null, matchMode: FilterMatchMode.CONTAINS },
    irrigationMin: { value: null, matchMode: FilterMatchMode.CONTAINS },
    irrigationMax: { value: null, matchMode: FilterMatchMode.CONTAINS },
    frequency: { value: null, matchMode: FilterMatchMode.CONTAINS },
    disableF: { value: null, matchMode: FilterMatchMode.CONTAINS }, // frequency - False = manual ,true = automatic
    correctionFactor: { value: null, matchMode: FilterMatchMode.EQUALS },
    disableCF: { value: null, matchMode: FilterMatchMode.CONTAINS },
    planDuration: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // Plot Settings
    DaytimeParMin: { value: null, matchMode: FilterMatchMode.CONTAINS }, // SETTINGS - // Daytime PAR value
    ATmax: { value: null, matchMode: FilterMatchMode.CONTAINS }, // "Air temperature max value"
    VPDmax: { value: null, matchMode: FilterMatchMode.CONTAINS }, // "VPD max value"
    VPDNIGHTmax: { value: null, matchMode: FilterMatchMode.CONTAINS }, // "VPD night max"
    SDIrate: { value: null, matchMode: FilterMatchMode.CONTAINS }, // ""Stem diameter growth change","
    MDSrate: { value: null, matchMode: FilterMatchMode.CONTAINS }, //  "Stem diameter shrinkage change",
    MdsMin: { value: null, matchMode: FilterMatchMode.CONTAINS }, //  "MDS min limit"
    MdsRateChange: { value: null, matchMode: FilterMatchMode.CONTAINS }, //  "Stem diameter shrinkage change decrease"
    MdsMinET: { value: null, matchMode: FilterMatchMode.CONTAINS }, // SDI Stress min ET
    PMBDelta: { value: null, matchMode: FilterMatchMode.CONTAINS }, // "PMB intensive",
    WaterNominalPressure: { value: null, matchMode: FilterMatchMode.CONTAINS }, //  "Water Nominal Pressure value",
  });

  useEffect(() => {
    setFilters((prev) => {
      prev.global.value = resellersStore.globalFilter.trim();
      return { ...prev };
    });
  }, [resellersStore.globalFilter]);

  const tableRows = useMemo(
    () => getMassEditTableRows(plotsStore?.plotMassSettings?.massSettings),
    [plotsStore?.plotMassSettings?.massSettings],
  );

  const tableColumnTitles = useMemo(() => {
    const titlesObject: { [key: string]: string } = {};
    tableKeys.forEach((tableKey) => {
      titlesObject[tableKey.key] = getColumnTitle(
        plotsStore.plotMassSettings.dssValidation?.schema,
        plotsStore.plotMassSettings.massSettings?.[0]?.settings?.plotSettings
          ?.schema,
        tableKey.key,
        tableKey.type,
      );
    });

    return titlesObject;
  }, [
    plotsStore.plotMassSettings.dssValidation,
    plotsStore.plotMassSettings.massSettings,
  ]);

  const allSelected = useMemo(() => {
    if (plotsStore.selectedMassEditRows.length === tableRows.length) {
      return true;
    }

    return false;
  }, [plotsStore.selectedMassEditRows.length, tableRows.length]);

  const onSelectAllChange = (event: DataTableSelectAllChangeParams) => {
    const selectAll = event.checked;

    if (selectAll) {
      plotsStore.setSelectedMassEditRows(tableRows);
    } else {
      plotsStore.setSelectedMassEditRows([]);
    }
  };

  const onRowClick = useCallback(
    ({ data }: DataTableSelectParams) => {
      const index = plotsStore.selectedMassEditRows.findIndex(
        (plot) => plot.id === data.id,
      );

      if (index !== -1) {
        const newSelectedRows = [...plotsStore.selectedMassEditRows].filter(
          (item) => item.id !== data.id,
        );

        plotsStore.setSelectedMassEditRows(newSelectedRows);
      } else {
        plotsStore.setSelectedMassEditRows([
          ...plotsStore.selectedMassEditRows,
          data,
        ]);
      }
    },
    [plotsStore],
  );

  const checkSelected = useCallback(
    (settings: MassEditPlotSettings) =>
      !plotsStore.selectedMassEditRows.find((plot) => plot.id === settings.id),
    [plotsStore.selectedMassEditRows],
  );

  const { getRowClassName } = useInactiveRowClassName({
    checkActive: checkSelected,
    rowClassName: HIGHLIGHTED_ROW_CLASS_NAME,
  });

  const getStatusColumnBody = useCallback(
    (key: keyof MassEditPlotSettings) => (settings: MassEditPlotSettings) =>
      getDataTableStatusColumnBody(settings, key),
    [],
  );

  if (resellersStore.resellerPlotsLoading) {
    return <Loading />;
  }

  return (
    <Grid flex={1}>
      <DataTableWrapper elevation={3}>
        <StyledDataTable
          showGridlines
          resizableColumns
          scrollable
          stripedRows
          dataKey="id"
          id="ResellerPlotsTable"
          filterDisplay="row"
          selectAll={allSelected}
          value={tableRows}
          selection={plotsStore.selectedMassEditRows}
          onSelectAllChange={onSelectAllChange}
          onRowSelect={onRowClick}
          onRowUnselect={onRowClick}
          scrollHeight={SCROLL_HEIGHT}
          rowClassName={getRowClassName}
          emptyMessage={t('empty_message')}
          filters={filters}
          globalFilterFields={globalFilterFields}
        >
          <Column selectionMode="multiple" style={COLUMN_STYLE_CHECK} />
          <Column
            headerTooltip={t('plot_id')}
            style={COLUMN_STYLE}
            sortable
            filter
            field="id"
            header={t('plot_id')}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="name"
            headerTooltip={t('plot_name')}
            header={t('plot_name')}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="soilMin"
            headerTooltip={tableColumnTitles?.soilMin}
            header={tableColumnTitles?.soilMin}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="soilMax"
            headerTooltip={tableColumnTitles?.soilMax}
            header={tableColumnTitles?.soilMax}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="irrigationMin"
            headerTooltip={tableColumnTitles?.irrigationMin}
            header={tableColumnTitles?.irrigationMin}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="irrigationMax"
            headerTooltip={tableColumnTitles?.irrigationMax}
            header={tableColumnTitles?.irrigationMax}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="frequency"
            headerTooltip={tableColumnTitles?.frequency}
            header={tableColumnTitles?.frequency}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="disableF"
            headerTooltip={tableColumnTitles?.disableF}
            header={tableColumnTitles?.disableF}
            body={getStatusColumnBody('disableF')}
            dataType="boolean"
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="correctionFactor"
            headerTooltip={tableColumnTitles?.correctionFactor}
            header={tableColumnTitles?.correctionFactor}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="disableCF"
            headerTooltip={tableColumnTitles?.disableCF}
            header={tableColumnTitles?.disableCF}
            body={getStatusColumnBody('disableCF')}
            dataType="boolean"
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="planDuration"
            headerTooltip={tableColumnTitles?.planDuration}
            header={tableColumnTitles?.planDuration}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="DaytimeParMin"
            headerTooltip={tableColumnTitles?.DaytimeParMin}
            header={tableColumnTitles?.DaytimeParMin}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="ATmax"
            headerTooltip={tableColumnTitles?.ATmax}
            header={tableColumnTitles?.ATmax}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="VPDmax"
            headerTooltip={tableColumnTitles?.VPDmax}
            header={tableColumnTitles?.VPDmax}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="VPDNIGHTmax"
            headerTooltip={tableColumnTitles?.VPDNIGHTmax}
            header={tableColumnTitles?.VPDNIGHTmax}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="SDIrate"
            headerTooltip={tableColumnTitles?.SDIrate}
            header={tableColumnTitles?.SDIrate}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="MDSrate"
            headerTooltip={tableColumnTitles?.MDSrate}
            header={tableColumnTitles?.MDSrate}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="MdsMin"
            headerTooltip={tableColumnTitles?.MdsMin}
            header={tableColumnTitles?.MdsMin}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="MdsRateChange"
            headerTooltip={tableColumnTitles?.MdsRateChange}
            header={tableColumnTitles?.MdsRateChange}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="MdsMinET"
            headerTooltip={tableColumnTitles?.MdsMinET}
            header={tableColumnTitles?.MdsMinET}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="PMBDelta"
            headerTooltip={tableColumnTitles?.PMBDelta}
            header={tableColumnTitles?.PMBDelta}
          />
          <Column
            style={COLUMN_STYLE}
            sortable
            filter
            field="WaterNominalPressure"
            headerTooltip={tableColumnTitles?.WaterNominalPressure}
            header={tableColumnTitles?.WaterNominalPressure}
          />
        </StyledDataTable>
      </DataTableWrapper>
    </Grid>
  );
});

export default ResellerPlotMassEdit;
