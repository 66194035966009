import React, { FC, useState, useCallback, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import { DialogActions, DialogContent, Divider } from '@mui/material';
import Dialog from 'components/UI/Dialog';
import Text from 'components/UI/Text';
import Button from 'components/UI/Button';
import { theme } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores/hooks/hooks';
import { ActionMode, DialogOnCloseReason } from 'models/shared';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import iconRedX from 'assets/images/icons/icon_x_red.svg';
import iconCheckGreen from 'assets/images/icons/icon_check_green.svg';

import { getColumnTitle, MassEditPlotSettings } from './ResellerPlotMassEdit';
import {
  getDssOrSettingsValues,
  SettingType,
  tableKeys,
} from './ResellerMassEdit.utils';
import MassEditInput from './MassEditInput';

const failedColor = '#ff40401F'; // opacity 0.12
const successColor = '#42B77D1F'; // opacity 0.12

const DEFAULT_INPUT: { [key: string]: number | boolean | undefined } = {
  id: undefined,
  name: undefined,
  // DSS Settings
  soilMin: undefined,
  soilMax: undefined,
  irrigationMin: undefined,
  irrigationMax: undefined,
  frequency: undefined,
  disableF: undefined,
  correctionFactor: undefined,
  disableCF: undefined,
  planDuration: undefined,
  // Plot Settings
  DaytimeParMin: undefined,
  ATmax: undefined,
  VPDmax: undefined,
  VPDNIGHTmax: undefined,
  SDIrate: undefined,
  MDSrate: undefined,
  MdsMin: undefined,
  MdsRateChange: undefined,
  MdsMinET: undefined,
  PMBDelta: undefined,
  WaterNominalPressure: undefined,
};

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;

const StyledMenuButton = styled(Button)`
  margin-top: auto;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 8px;
`;

const SuccessAmountsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const SuccessAmount = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const SuccessHeaderText = styled(Text)`
  color: #42b77d;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
`;

const SuccessAmountText = styled(Text)`
  color: #052b33;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  align-content: center;
`;

const StyledHeaderText = styled(Text) <{ $isHeader?: boolean }>`
  text-overflow: ellipsis;
  color: ${(p) => (p.$isHeader ? 'white' : '#052B33')};
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
`;

const DialogBody = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const DialogBodyContainer = styled.div`
  display: inline-flex;
  padding: 1rem 1rem;
  flex-direction: column;
`;

const TableFlexRow = styled.div<{
  $successColor?: string;
  $disabled?: boolean;
}>`
  display: flex;
  flex-direction: row;
  opacity: ${(p) => (p.$disabled ? 0.5 : 1)};
  pointer-events: ${(p) => (p.$disabled ? 'none' : 'auto')};
  background: ${(p) => (p.$successColor ? `${p.$successColor}` : 'white')};
`;

const TableItem = styled.div<{ $isHeader?: boolean }>`
  background: ${(p) => (p.$isHeader ? '#052B33' : 'unset')};
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100px;
  max-width: 100px;
  border: 1px solid #e0e0e0;
  max-height: 50px;
  min-height: 50px;
  padding: 5px 10px;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  margin: 1rem 0;
`;

const ScrollableTableContainer = styled.div<{ $height: string }>`
  display: inline-flex;
  flex-direction: column;
  height: ${(p) => p.$height || 'auto'};
  max-height: calc(100vh - 450px);
  overflow-x: hidden;
  overflow-y: visible;
`;

const TitleText = styled(Text)`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
`;

interface PlotSettingsManagementDialogProps {
  onHide: () => void;
  visible: boolean;
  title: string;
  mode: ActionMode;
}

const StyledDialogContent = styled(DialogContent)`
  padding: 0 1rem;
  overflow-y: hidden;
  display: flex;
`;

// This is due to Disable CF and disable F requiring 'Auto' or 'Manual' text instead of boolean
const getValueByKeyValue = (key: string, value: any) => {
  const isBoolean = typeof value === 'boolean';
  const isDisable = key.includes('disable');

  if (!isBoolean) {
    return value;
  }

  if (isDisable) {
    return value ? 'Auto' : 'Manual';
  }

  return value ? <CheckIcon /> : <CloseIcon />;
};

type SuccessMap = Map<string | number, { success: boolean; color: string }>;

const ResellerMassEditDialog: FC<PlotSettingsManagementDialogProps> = observer(
  ({ visible = false, onHide, title, mode }) => {
    const { t } = useTranslation('plot');
    const { t: growerT } = useTranslation('grower');
    const { t: generalT } = useTranslation('general');
    const { plotsStore, userStore, resellersStore } = useStores();
    const settingsSchema =
      plotsStore?.plotMassSettings?.massSettings?.[0]?.settings?.plotSettings
        ?.schema;

    const dssSchema = plotsStore?.plotMassSettings?.dssValidation?.schema;

    const bottomTableHeightString = useMemo(
      () => `${(plotsStore?.selectedMassEditRows?.length || 1) * 50 + 100}px`,
      [plotsStore?.selectedMassEditRows],
    );

    const [apiSuccessMap, setApiSuccessMap] = useState<SuccessMap | undefined>(
      undefined,
    );

    const editingDisabled = useMemo(() => !!apiSuccessMap, [apiSuccessMap]);

    const apiSuccessAmount = useMemo(() => {
      if (apiSuccessMap) {
        const successAmount = Array.from(apiSuccessMap?.values()).filter(
          (item) => item.success,
        ).length;

        const failAmount = Array.from(apiSuccessMap?.values()).filter(
          (item) => !item.success,
        ).length;

        return { successAmount, failAmount };
      }

      return undefined;
    }, [apiSuccessMap]);

    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const hasError = useMemo(
      () => Object.values(errors).some((error) => error),
      [errors],
    );

    const [massValues, setMassValues] = useState(DEFAULT_INPUT);

    const hasEdited = useMemo(() => {
      return Object.keys(massValues).some(
        (key) => massValues?.[key] !== DEFAULT_INPUT?.[key],
      );
    }, [massValues]);

    const [isSaving, setIsSaving] = useState(false);

    const onChangeInput = useCallback(
      (value: any, error: boolean, key: string) => {
        const keyInErrors = Object.keys(errors).includes(key);
        const shouldSetError =
          (error && !keyInErrors) || (!error && keyInErrors);

        if (shouldSetError) {
          setErrors((prev) => {
            if (keyInErrors && error === false) {
              const newErrors = { ...prev };
              delete newErrors[key];
              return newErrors;
            }

            return {
              ...prev,
              [key]: error,
            };
          });
        }

        setMassValues((prev) => {
          return {
            ...prev,
            [key]: value,
          };
        });
      },
      [errors],
    );

    const tableColumnTitles = useMemo(() => {
      const titlesObject: { [key: string]: string } = {};
      tableKeys.forEach((tableKey) => {
        titlesObject[tableKey.key] = getColumnTitle(
          plotsStore.plotMassSettings.dssValidation?.schema,
          plotsStore.plotMassSettings.massSettings?.[0]?.settings?.plotSettings
            ?.schema,
          tableKey.key,
          tableKey.type,
        );
      });

      return titlesObject;
    }, [
      plotsStore.plotMassSettings.dssValidation,
      plotsStore.plotMassSettings.massSettings,
    ]);

    const closeDialog = useCallback(() => {
      onHide();
    }, [onHide]);

    const handleResetCloseDialog = useCallback(() => {
      plotsStore.setSelectedMassEditRows([]);
      closeDialog();
    }, [closeDialog, plotsStore]);

    const handleCloseDialog = useCallback(
      (event: unknown, reason: DialogOnCloseReason) => {
        if (reason && reason === DialogOnCloseReason.BackDropClick) {
          return;
        }

        handleResetCloseDialog();
      },
      [handleResetCloseDialog],
    );

    const handleSubmitClick = useCallback(async () => {
      setIsSaving(true);
      const dssData = getDssOrSettingsValues(massValues, SettingType.dss);
      const settingsData = getDssOrSettingsValues(
        massValues,
        SettingType.settings,
      );

      const res = (await plotsStore.postPlotsMassEdit(
        dssData,
        settingsData,
        plotsStore?.selectedMassEditRows,
      )) as { plotId: number; dssSuccess: boolean; settingsSuccess: boolean }[];

      const successMap: SuccessMap = new Map();
      res.forEach((item) => {
        successMap.set(item?.plotId, {
          success: item?.dssSuccess && item?.settingsSuccess,
          color:
            item?.dssSuccess && item?.settingsSuccess
              ? successColor
              : failedColor,
        });
      });

      setApiSuccessMap(successMap);

      setIsSaving(false);
      // closeDialog();
    }, [massValues, plotsStore]);

    return (
      <Dialog
        open={visible}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
        dialogTitle={title}
        titleWeight="bold"
        disableEscapeKeyDown
      >
        <StyledDialogContent>
          <DialogBodyContainer>
            <DialogBody>
              <TitleContainer>
                <TitleText textcolor={theme.color.darkAlt}>
                  {growerT('insert_new_values')}
                </TitleText>
              </TitleContainer>
              <TableFlexRow $disabled={!!editingDisabled}>
                {tableKeys.map((tableKey) => (
                  <TableItem $isHeader>
                    <StyledHeaderText
                      $isHeader
                      key={tableKey.key}
                      title={tableColumnTitles[tableKey.key] || tableKey.key}
                    >
                      {tableColumnTitles[tableKey.key] || tableKey.key}
                    </StyledHeaderText>
                  </TableItem>
                ))}
              </TableFlexRow>
              <TableFlexRow $disabled={!!editingDisabled}>
                {tableKeys.map((tableKey) => {
                  if (tableKey.key === 'id' || tableKey.key === 'name') {
                    return <TableItem />;
                  }

                  return (
                    <TableItem>
                      <MassEditInput
                        formKey={tableKey.key}
                        dssSchema={dssSchema}
                        key={tableKey.key}
                        value={massValues?.[tableKey?.key]}
                        settingsSchema={settingsSchema}
                        handleChangeValue={onChangeInput}
                      />
                    </TableItem>
                  );
                })}
              </TableFlexRow>
              <TitleContainer>
                <TitleText textcolor={theme.color.darkAlt}>
                  {growerT('current_values')}
                </TitleText>
              </TitleContainer>
              <ScrollableTableContainer $height={bottomTableHeightString}>
                <TableFlexRow>
                  {tableKeys.map((tableKey) => (
                    <TableItem $isHeader>
                      <StyledHeaderText
                        $isHeader
                        key={tableKey.key}
                        title={tableColumnTitles[tableKey.key] || tableKey.key}
                      >
                        {tableColumnTitles[tableKey.key] || tableKey.key}
                      </StyledHeaderText>
                    </TableItem>
                  ))}
                </TableFlexRow>
                {plotsStore?.selectedMassEditRows?.map((row) => {
                  return (
                    <TableFlexRow
                      $successColor={apiSuccessMap?.get(row.id)?.color}
                    >
                      {tableKeys.map((tableKey) => (
                        <TableItem>
                          <Text key={tableKey.key}>
                            {getValueByKeyValue(
                              tableKey.key,
                              row?.[
                              tableKey?.key as keyof MassEditPlotSettings
                              ],
                            )}
                          </Text>
                        </TableItem>
                      ))}
                    </TableFlexRow>
                  );
                })}
              </ScrollableTableContainer>
            </DialogBody>
          </DialogBodyContainer>
        </StyledDialogContent>
        {/* <Divider /> */}
        {!apiSuccessAmount && (
          <DialogActions>
            <Button
              onClick={handleSubmitClick}
              disabled={hasError || !hasEdited}
              loading={isSaving}
            >
              <Text textcolor={theme.color.white} size="sm">
                {generalT('apply')}
              </Text>
            </Button>
            <Button onClick={handleResetCloseDialog}>
              <Text textcolor={theme.color.white} size="sm">
                {generalT('cancel')}
              </Text>
            </Button>
          </DialogActions>
        )}

        {apiSuccessAmount && (
          <StyledDialogActions>
            <SuccessContainer>
              <SuccessHeaderText>
                {growerT('mass_editing_complete')}
              </SuccessHeaderText>
              <SuccessAmountsContainer>
                <SuccessAmount>
                  <img src={iconCheckGreen} alt="icon_successful_plots" />
                  <SuccessAmountText>
                    {growerT('mass_editing_plots_succeded', {
                      count: apiSuccessAmount?.successAmount,
                    })}
                  </SuccessAmountText>
                </SuccessAmount>
                <SuccessAmount>
                  <img src={iconRedX} alt="icon_failed_plots" />
                  <SuccessAmountText>
                    {growerT('mass_editing_plots_failed', {
                      count: apiSuccessAmount?.failAmount,
                    })}
                  </SuccessAmountText>
                </SuccessAmount>
              </SuccessAmountsContainer>
            </SuccessContainer>
            <StyledMenuButton onClick={closeDialog}>
              <Text textcolor={theme.color.white} size="sm">
                {generalT('done')}
              </Text>
            </StyledMenuButton>
          </StyledDialogActions>
        )}
      </Dialog>
    );
  },
);

export default ResellerMassEditDialog;
