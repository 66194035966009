import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { Column } from 'components/shared/DataTable/DataTable';
import { WebPlot } from 'models/plot';
import DataTable from 'components/shared/DataTable';
import { ActionMode } from 'models/shared';
import { GrowerSystem } from 'models/grower';
import { DropdownProps } from 'primereact/dropdown';
import { SystemSensorType, SystemVariant } from 'models/systems';
import { EditableSensor, Sensor } from 'models/sensor';
import { useStores } from 'stores/hooks/hooks';
import { checkIsSensorValveType } from 'utils/plotSensorsUtils';
import useSensorsTableRowsActions from './useSensorsTableRowsActions';
import EditTableRowDialog from './EditTableRowDialog';
import {
  convertRowDataToLatLngRowData,
  convertRowDataToSensors,
  convertSensorsToRowData,
} from './SensorsEditableTable.utils';

const TABLE_HEIGHT = '34vh';
const disabledColumnClassName = 'data-table-column-disabled';

const TableContainer = styled.div<{ height: string }>`
  max-height: ${({ height }) => height};
  overflow: hidden;
`;

const StyledDataTable = styled(DataTable)`
  td {
    cursor: pointer;
  }

  .data-table-column {
    &-lg {
      min-width: 10rem;
    }

    &-disabled {
      background-color: ${({ theme }) => theme.color.tinyGray};
      cursor: auto;
    }

    &-button {
      max-width: 4rem;
      justify-content: center;
    }

    &-checkbox {
      max-width: 5rem;
      justify-content: center;
    }
  }

  .p-dropdown-label {
    padding-left: 0.1rem;
    padding-right: 0;
  }

  .p-datatable-wrapper .p-datatable-table td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .multiselect-column {
    .p-column-title + span {
      width: 100%;
    }
  }
`;

interface SelectableValue<V, O> {
  value: V;
  options: O;
}

export type SensorsSelectableValue = SelectableValue<
  string | number,
  DropdownProps['options']
>;

export type SensorsSelectablePlotValue = SelectableValue<
  WebPlot[],
  EditableSensorPlotOption[]
>;

export interface EditableSensorPlotOption {
  label: string;
  value: WebPlot;
}

export interface SensorsEditableRow {
  id: number;
  selected: boolean;
  sensorSerial: string;
  name: string;
  sensorType: string;
  latitude: number;
  longitude: number;
  group: SensorsSelectableValue;
  type: SensorsSelectableValue;
  plot: SensorsSelectablePlotValue;
  originalSensor: Sensor;
  alias: string;
  params: {
    altitude?: number;
    depth?: number;
    companyUuid?: string;
    valveId?: string;
    alias?: string;
    uuid?: string;
    id?: string | number;
  };
}

export interface SensorsEditableRowOptions {
  plot: EditableSensorPlotOption[];
}

interface SensorsEditableTableProps {
  mode?: ActionMode;
  system: GrowerSystem;
  sensors: EditableSensor[];
  sensorTypes: SystemSensorType[];
  options: SensorsEditableRowOptions;
  showActiveColumn?: boolean;
  tableHeight?: string;
  disabled?: boolean;
  onChange(data: EditableSensor[]): void;
}

export const SensorsEditableTable: FC<SensorsEditableTableProps> = observer(
  ({
    mode = ActionMode.CREATE,
    system,
    sensors,
    sensorTypes,
    options,
    showActiveColumn = false,
    tableHeight = TABLE_HEIGHT,
    onChange,
    disabled,
    ...rest
  }) => {
    const { t } = useTranslation('system');
    const { systemsStore } = useStores();
    const [tableRows, setTableRows] = useState<SensorsEditableRow[]>([]);
    const commonColumnsClassName = disabled ? disabledColumnClassName : '';

    const onRowsChange = useCallback(
      (rows: SensorsEditableRow[]) => {
        // Upon selected a plot , apply its latitude and longitude to the sensor
        const modifiedRowsWithLatLngByPlot =
          convertRowDataToLatLngRowData(rows);

        const updatedSensors = convertRowDataToSensors(
          modifiedRowsWithLatLngByPlot,
          sensors,
          sensorTypes,
        );

        onChange(updatedSensors);
        setTableRows(
          convertSensorsToRowData(updatedSensors, sensorTypes, options),
        );
      },
      [sensors, sensorTypes, onChange, options],
    );

    const {
      onEditRowDialogClose,
      onEditRowDialogSubmit,
      getCheckboxBody,
      getButtonBody,
      getSelectBody,
      getMultiSelectBody,
      getCellEditor,
      onCellEditComplete,
      isMoreDialogVisible,
      editableRowValues,
    } = useSensorsTableRowsActions({
      mode,
      disabled,
      rows: tableRows,
      setRows: onRowsChange,
    });

    const sensorSystemId = system?.id as number;
    const editableSensor = editableRowValues?.originalSensor;
    const isCompanyFieldVisible = editableSensor?.type === SystemVariant.IW;
    const isValveFieldVisible = checkIsSensorValveType(editableSensor);

    useEffect(() => {
      setTableRows(convertSensorsToRowData(sensors, sensorTypes, options));
    }, [sensors, sensorTypes, options]);

    useEffect(() => {
      if (isValveFieldVisible && sensorSystemId) {
        systemsStore.getSystemValves(sensorSystemId);
      }
    }, [systemsStore, sensorSystemId, isValveFieldVisible]);

    return (
      <Paper elevation={1} {...rest}>
        <TableContainer height={tableHeight}>
          <StyledDataTable
            id="SensorsEditableTable"
            showGridlines
            resizableColumns
            value={tableRows}
            scrollable
            dataKey="id"
            emptyMessage={t('empty_message')}
            stripedRows
            editMode="cell"
            scrollHeight={tableHeight ?? TABLE_HEIGHT}
          >
            {showActiveColumn && (
              <Column
                field="selected"
                header=""
                body={getCheckboxBody}
                dataType="boolean"
                className="data-table-column-checkbox"
              />
            )}
            <Column
              field="sensorSerial"
              header={t('sensor_serial')}
              className={disabledColumnClassName}
            />
            <Column
              field="sensorType"
              header={t('sensor_type')}
              className={disabledColumnClassName}
            />
            <Column
              field="name"
              header={t('name')}
              editor={getCellEditor}
              onCellEditComplete={onCellEditComplete}
              dataType="text"
              className={commonColumnsClassName}
            />
            <Column
              field="latitude"
              header={t('latitude')}
              editor={getCellEditor}
              onCellEditComplete={onCellEditComplete}
              dataType="number"
              className={commonColumnsClassName}
            />
            <Column
              field="longitude"
              header={t('longitude')}
              editor={getCellEditor}
              onCellEditComplete={onCellEditComplete}
              dataType="number"
              className={commonColumnsClassName}
            />
            <Column
              field="group"
              header={t('group')}
              body={getSelectBody}
              className={commonColumnsClassName}
            />
            <Column
              field="type"
              header={t('type')}
              body={getSelectBody}
              className={commonColumnsClassName}
            />
            <Column
              field="plot"
              header={t('plot')}
              body={getMultiSelectBody}
              className={`multiselect-column ${commonColumnsClassName} ${mode === ActionMode.EDIT ? disabledColumnClassName : ''
                }`}
            />
            <Column
              header={t('more')}
              body={getButtonBody}
              className="data-table-column-button"
            />
          </StyledDataTable>
        </TableContainer>
        {isMoreDialogVisible && (
          <EditTableRowDialog
            title={`${disabled ? t('sensor') : t('edit_sensor')} ${editableRowValues.name}`}
            onClose={onEditRowDialogClose}
            onSubmit={onEditRowDialogSubmit}
            defaultValues={editableRowValues.params}
            isValveFieldVisible={isValveFieldVisible}
            isCompanyFieldVisible={isCompanyFieldVisible}
            disabled={disabled}
          />
        )}
      </Paper>
    );
  },
);
