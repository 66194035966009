import { createTheme } from '@mui/material/styles';

const theme = {
  unit: 'rem',
  fontFamily: 'Source sans',
  fontSize: {
    text: '1rem',
    sub: '0.8rem',
    heading1: 'calc(1.625rem + 1.6vw)',
    heading2: 'calc(1.375rem + 1.5vw)',
    heading3: 'calc(1.25rem + 1vw)',
    heading4: 'calc(1.135rem + .6vw)',
    heading5: 'calc(1.125rem + .25vw)',
    heading6: 'calc(1rem + .25vw)',
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    xxl: '22px',
    verySmall: '12px',
  },
  color: {
    base: '#383855',
    text: '#606077',
    defaultText: '#C4C4C4',
    primary: '#195a63',
    disable: '#cccccc',
    link: '#2460c3',
    white: '#ffffff',
    red: '#c13636',
    brightRed: '#ff4040',
    brightGreen: '#42b77d',
    silkenJade: '#0fa79d',
    cascadingWhite: '#f6f6f6',
    verditer: '#00b8ad',
    selectedTableRowLime: '#e8f5e9',
    antiqueTin: '#6d6e71',
    black: '#000000',
    warning: '#ff9800',
    error: '#f44336',
    primeBorder: '#dee2e6',
    success: '#689F38',
    gray: '#808080',
    leafGreen: '#93c020',
    lightGreen: '#b1ed64',
    paleAqua: '#e0f1e9',
    divider: 'rgba(0,0,0,0.12)',
    inputBorder: 'rgba(0, 0, 0, 0.23)',
    border: '#dee2e6',
    transparent: 'transparent',
    amber: '#ff9100',
    tinyGray: 'rgba(0, 0, 0, 0.04)',
    paleYellow: '#fffcc9',
    palePink: '#fdf2f3',
    darkAlt: '#052B33',
    alertLightRed: '#FF999C',
    alertLightYellow: '#FFBE8F',
    alertLightGreen: '#FFEF80',
  },
  fonts: {
    sourceSansPro: 'Source Sans Pro, Assistant, sans-serif',
    metropolis: 'Metropolis',
  },
  palette: {
    primary: { main: '#4CAF50', contrastText: '#ffffff' },
  },
  typography: {
    fontFamily: ['Metropolis'].join(','),
  },
};

export default theme;

export const muiTheme = createTheme(theme);
