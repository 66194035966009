import { EditableSensor, Sensor } from 'models/sensor';
import { getUnique } from 'utils/functions';
import { DropdownProps } from 'primereact/dropdown';
import { SystemSensorType } from 'models/systems';
import {
  SensorsEditableRow,
  SensorsEditableRowOptions,
} from './SensorsEditableTable';

const getOptions = (values: string[]): DropdownProps['options'] => {
  return getUnique(values).map((value) => ({
    value,
    label: value,
  }));
};

const getTypeOptions = (
  { sensorType }: Sensor,
  sensorTypes: SystemSensorType[],
): DropdownProps['options'] => {
  if (!sensorType?.group) {
    return getOptions(sensorTypes.map(({ type }) => type));
  }

  const types: string[] = [];
  sensorTypes.forEach(({ group, type }) => {
    if (group === sensorType?.group) {
      types.push(type);
    }
  });

  return getOptions(types);
};

export const convertSensorsToRowData = (
  sensors: EditableSensor[],
  sensorTypes: SystemSensorType[],
  options: SensorsEditableRowOptions,
): SensorsEditableRow[] => {
  return sensors.map(({ sensor, details }, index) => {
    const { params, sensorType } = sensor;
    const sensorParams = params ?? {};
    const typeOptions = getTypeOptions(sensor, sensorTypes);
    const groupOptions = getOptions(sensorTypes.map(({ group }) => group));

    return {
      selected: !!details.selected,
      id: sensor.id ?? index,
      sensorSerial: sensor.serial ?? '',
      sensorType: sensor.type,
      name: sensor.displayName,
      latitude: sensor.latitude,
      longitude: sensor.longitude,
      originalSensor: sensor,
      params: { ...sensorParams, alias: sensor.alias },
      group: {
        value: sensorType?.group ?? '',
        options: groupOptions,
      },
      type: {
        value: sensorType?.type ?? '',
        options: typeOptions,
      },
      plot: {
        value: details.plots ?? [],
        options: options.plot,
      },
    } as SensorsEditableRow;
  });
};

const getSelectedSensorType = (
  row: SensorsEditableRow,
  sensorTypes: SystemSensorType[],
): string => {
  const selectedGroup = row.group.value as string;
  const selectedType = row.type.value as string;
  if (selectedGroup && !selectedType) {
    return sensorTypes.find(({ group }) => group === selectedGroup)?.type ?? '';
  }

  if (selectedGroup && selectedType) {
    const isSameGroup =
      sensorTypes.find(({ type }) => type === selectedType)?.group ===
      selectedGroup;

    return isSameGroup
      ? selectedType
      : sensorTypes.find(({ group }) => group === selectedGroup)?.type ?? '';
  }

  return selectedType;
};

export const convertRowDataToSensors = (
  rows: SensorsEditableRow[],
  sensors: EditableSensor[],
  sensorTypes: SystemSensorType[],
): EditableSensor[] => {
  return rows.map((row, index) => {
    const { sensor } = sensors[index];
    const selectedSensorType = getSelectedSensorType(row, sensorTypes);
    const sensorType = sensorTypes.find(
      ({ type }) => type === selectedSensorType,
    );

    return {
      sensor: {
        ...sensor,
        sensorType,
        latitude: Number(row?.latitude),
        longitude: Number(row?.longitude),
        displayName: row.name,
        alias: row.params?.alias,
        params: row.params ?? {},
      },
      details: {
        selected: row.selected,
        plots: row?.plot.value ?? [],
      },
    };
  }) as EditableSensor[];
};

export const convertRowDataToLatLngRowData = (
  rows: SensorsEditableRow[],
): SensorsEditableRow[] => {
  return rows.map((row) => {
    const plot = row.plot.value?.[0];
    const { latitude, longitude } = plot || {};
    if (!row.latitude && !row.longitude && latitude && longitude) {
      return {
        ...row,
        latitude,
        longitude,
      };
    }

    return row;
  });
};
